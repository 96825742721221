import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    actOrderManagementList :[],
    actOrderManagementLoading:false,
    actOrderManagementError:false
}

const userReducer = createSlice({
    name: 'OrderManagementActivitiesReducer',
    initialState,
    reducers:{
        getOrderManagementAct(state){
            return { ...state, actOrderManagementLoading: true };
        },
        getOrderManagementAct_successful(state, action) {
            return {
                ...state, actOrderManagementList: action.payload.body,
                actOrderManagementLoading: false
            }
        },
        getOrderManagementAct_error(state, action) {
            return {
            ...state, actOrderManagementList: action.payload,
            actOrderManagementError: true
            }
        },
        resetStatus(state) {
            state.actOrderManagementList = {}            
          },
        
    }
})


export const { getOrderManagementAct, getOrderManagementAct_successful, getOrderManagementAct_error, resetStatus } = userReducer.actions
export default userReducer.reducer