import {React,Fragment} from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { Childs } from "../constants";
import {BrView} from '../pages/PPP/Activities/ActivityTypes/ActivityTypeView'
import Box from "@mui/material/Box";

const common_tabs = ['Home','Activities']

export  function dynoNavigate(Component) {
    const Activityalloc = (props) => {
      const navigate = useNavigate();
      const location = useLocation();
      const activityClick = (selectedActDetails) => {
      const dynoRoute = props?.activitydetails
      const crdData = location.state?.data
      const rtpath = Childs[(selectedActDetails?.ActivityId).toUpperCase()]
      const titlepath = `${location?.pathname}/${rtpath}`
      navigate(Childs[(selectedActDetails?.ActivityId).toUpperCase()], {
        state: {
          data : {...crdData,...dynoRoute},
          obj :  [dynoRoute?.displayname],
          title : {...location?.state?.title,[titlepath]:dynoRoute?.displayname}
        },
      });
      }
      return<Component {...props} onClick={()=>activityClick(props)} />;
    }
    return Activityalloc;
  };
export  function enRouteActivity(Component) {
    const RouteDir = (props) => {
      const location = useLocation();
      let dpName  = common_tabs.concat(location.state?.obj);
      const pathnames = location.pathname.split('/').filter((x) => x);  
      return<Fragment>
          <div>
            <BrView crdData = {location.state?.data} crdNames={location.state?.obj} dpName={dpName} paths={pathnames}/>
            <Box style={{ fontSize: 13, letterSpacing: 0.3,paddingTop:10, height: 40, width: "100%", color: "#323232", fontWeight: 600 }}>{location?.state?.title?.[location.pathname]??''}</Box>
          </div>
          <Component {...props}/>
          </Fragment>;
    }
    return RouteDir;
  };

