/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, Fragment, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MDTextField from '../../../../components/ui/MDTextField'
import Loader from "../../../../components/ui/Loader";
import { openSnackbar } from "../../../../components/ui/Toastr";
import MDDateField from '../../../../components/ui/MDDateField'
import { useDispatch, useSelector } from "react-redux";
import { apiURL } from "../../../../services/apiUrlConfig";
import { getPdfDownLoadableConfigData } from "../../../../store/reducers/common";
import { updatecreateDocument, resetStatus } from "../../../../saga/saga/createDocumentSaga"
import { getFilePath, resetStatus as getFilePathresetStatus } from "../../../../saga/saga/downloadFileSaga"
import { saveDocumentData, resetStatus as resetDocStatus } from "../../../../saga/saga/documentSave"
import './doccreate.scss'
import { DocumentTextArea } from '../CreateContracts/DocumentTextArea'

import {
  getDocumentData,
  // eslint-disable-next-line no-unused-vars
  resetStatus as resetDocument
} from "../../../../saga/saga/documentUpload"
import util from "util";
import Authorization from "../../../../utils/authorization";
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import CircularProgress from '@mui/material/CircularProgress';
import MiModal from "../../../../components/ui/MiModal"
import { CONTRACT_DOCUMENT_POPUP_MESSAGE } from "../../../../constants"
//import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


// const isAmericanCountry = Authorization.isAmerica()

const ENTITY = 'Entity';
const SMALLSEED = 'Small Seed';
const LARGESEED = 'Large Seed'
const SUBMITTEDBY = 'Submitted On';
const OTHERFIELDS = 'Other Fields';
const FREETEXT = 'Free Text';
const SCHEDULEB = 'Schedule B';
const SCHEDULEC = 'Schedule C';
const TITLE = 'Contract Document';
const ACTION_EDIT = 'Edit Targets';
const ACTION_SAVE = 'Save';
const ACTION_SAVESEND = 'Save & Send';
const ACTION_DOWNLOAD = 'Download';
const ACTION_PRINT = 'Print';
// const SKIP = 'Skip';
const ACTION_NEXT = 'Next'
const SUCCESS = 200;
const ERROR_MSG = "Please check internet or try again!";
const breakOpenLength = 3
const underlineOpenLength = 3
const breakCloseLength = 4
const underLineCloseLength = 4
const BASE_API_PATH = process.env.REACT_APP_API;

 /**
        Upcoming Implementation
  */

const useStyles = {
  onerowfirst: {
    width: '50%',
    justifyContent: 'start',
    fontSize: 14
  },
  onerowsecond: {
    width: '50%',
    display: 'flex',
    justifyContent: 'end',
    fontSize: 10

  },
  close: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    color: 'white',
    backgroundColor: 'transparent'
  },
  mainroot: {
    display: 'flex',
    marginBottom: 10
  },
  mainboxroot: {
    backgroundColor: 'transparent',
    color: 'white',
    height: '15%'
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'transparent',
  border: '0px solid #000',
  boxShadow: 0,
  p: 0,
};
const styledoc = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'transparent',
  border: '0px solid #000',
  boxShadow: 0,
  p: 0,
};

const controlProps = () => ({
  name: 'size-radio-button-demo',
});

const AdditionalServiceFee = (props) => {
  const handleUpdate = (event) => {
    props.updateValue(event.target.value);
  }

  return (
    <FormControl style={{ marginLeft: 3, marginTop: 20, height: 60 }}>
      <FormLabel style={{ fontSize: 11, color: 'rgba(0, 0, 0, 0.87)' }} id="demo-row-radio-buttons-group-label">{'Additional Service & Fee ?'}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={props.value || 'No'}
        onChange={handleUpdate}
      >
        <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }} value="Yes" control={
          <Radio  {...controlProps('c')}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 18,
              },
            }}
          />
        } label="Yes" />
        <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }} style={{ fontSize: 11 }} value="No" control={
          <Radio
            {...controlProps('c')}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 18,
              },
            }}
          />
        } label="No" />
      </RadioGroup>
    </FormControl>
  );
}


// eslint-disable-next-line no-unused-vars
const CircularProgressWithLabel = (props) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}



export default function DocumentCreate(props) {
  const [open] = useState(props.set);
  const [allowSmallSeed, setAllowSmallSeed] = useState(false)
  const [allowLargeSeed, setAllowLargeSeed] = useState(false)

  const [smallSeed, setSmallSeed] = useState('')
  const [largeSeed, setLargeSeed] = useState('')


  // const [rowData,setRowData] = useState(props.rowData || [])
  const rowData = props.rowData || []
  // const [progress, setProgress] = useState(10);
  const [Entity, setEntity] = useState('Bayer LLC');
  const [savedFilePath, setSavedFilePath] = useState('');
  const [FreeText, setFreeText] = useState('');
  const [ScheduleB, setScheduleB] = useState('');
  const [ScheduleC, setScheduleC] = useState('');
  const [SubmittedBy, setSubmittedBy] = useState('');
  const [AdditionalFee, setAdditionalFee] = useState('No');
  const [OtherFields, setOtherFields] = useState('');
  const [pdfUrl, setPdfUrl] = useState('')
  const [Error, setError] = useState(false);
  const [saved, setSaved] = useState(rowData?.itmObj?.iscontractsaved || false)
  const [DocCreate, setDocCreate] = useState((rowData?.itmObj?.iscontractsaved) ? true : false);
  const [Next, setNext] = useState(true);
  const [acceptTc, setAcceptTc] = useState(false)
  // eslint-disable-next-line
  const [additionalFieldValidation, setAdditionalFieldValidation] = useState(false)
  const [isLoading, setLoading] = useState(true);

  const [miModalShow,setMiModalShow] = useState(false)
  const handleClose = () => ((SubmittedBy.length !== 0 ||  OtherFields.length !== 0) && !miModalShow && !saved)  ? setMiModalShow(true) : props.close(props.set);
  const UpdateEntity = (value) => setEntity(value)
  const UpdateFreeText = (value) => {setFreeText(value)}
  const UpdateScheduleB = (value) => {setScheduleB(value)}
  const UpdateScheduleC = (value) => {setScheduleC(value)}
  const UpdateSubmittedBy = (value) => setSubmittedBy(value)
  const submitDocumentDetails = useSelector((state) => state.createDocumentSaga.createDocumentDetails);
  const submitDocumentStatus = useSelector((state) => state.createDocumentSaga.createDocumentloading);
  const submitDocumentError = useSelector((state) => state.createDocumentSaga.createDocumentError);

  const getDocumentDetails = useSelector((state) => state.documentUpload.uploadDocumentDetails);
  const getDocumentStatus = useSelector((state) => state.documentUpload.uploadDocumentLoading);
  const getDocumentError = useSelector((state) => state.documentUpload.uploadDocumentError);

  const saveDocumentDetails = useSelector((state) => state.documentSave.saveDocumentDetails);
  const saveDocumentStatus = useSelector((state) => state.documentSave.saveDocumentLoading);
  const saveDocumentError = useSelector((state) => state.documentSave.saveDocumentError);

  const getDownloadFilePath = useSelector((state) => state.downloadFileSaga.filePathDetails);
  const getDownloadFilePathStatus = useSelector((state) => state.downloadFileSaga.filePathloading);
  const getDownloadFilePathError = useSelector((state) => state.downloadFileSaga.filepathError);
  const isAmericanCountry = Authorization.isAmerica()


  useEffect(() => {
    return () => {
      dispatch(resetDocStatus());
   }
}, [])

useEffect(()=>{
// console.log("otherFieldRawData::>::",OtherFields)
},[OtherFields])

  // const ref = useRef(null);
  const dispatch = useDispatch()

  const UpdateAdditionalFee = (value) => {
    setAdditionalFee(value)
    value && setError(false)
  }
  const UpdateOtherFields = (value) => {
  //   var form_data = new FormData();
  //   form_data.append('otherfields', value);
  //   console.log("convertedformdata::",form_data)

  //   var object = {};
  //   form_data.forEach(function(value, key){
  //   object[key] = value;
  //  });
  //  var json = JSON.stringify(object);
  //  console.log("convertedformdatajson::",json)


    setOtherFields(value)
    value && setError(false)
  }
  // const handleSkip = () => {
  //   if (Next) {
  //     setNext(false)
  //   } else {
  //     setDocCreate(true)

  //   }
  // }

  useEffect(() => {
    dispatch(getPdfDownLoadableConfigData(props.rowData))
  }, [props.rowData])

  const CreateDocSchema = () => {
    const reqBodyObj = {
      'additionalservicetext': FreeText,
      'otherfields': OtherFields,
      'additionalserviceschedule': ScheduleB,
      'additionalserviceschedulec': ScheduleC,
      'contractid': props.rowData?.itmObj?.id || '',
      'submitbydate': SubmittedBy,
      'additionalservices': AdditionalFee === 'NO' ? false : true,
      'smallseedvarieties':smallSeed?.length>0?smallSeed:null,
      'largeseedvarieties':largeSeed?.length>0?largeSeed:null
    }
  
    // form_data_cds.append('additionalservicetext', FreeText);
    // form_data_cds.append('otherfields', OtherFields);
    // form_data_cds.append('additionalserviceschedule', ScheduleB);
    // form_data_cds.append('additionalserviceschedulec', ScheduleC);
    // form_data_cds.append('contractid', props.rowData?.itmObj?.id || '');
    // form_data_cds.append('submitbydate', SubmittedBy);
    // form_data_cds.append('additionalservices', AdditionalFee === 'NO' ? false : true);
    return reqBodyObj
  }
  const SaveDocSchema = () => {
    return {
      "ids": [props.rowData?.itmObj?.id || ''],
      "iscontractsaved": true,
    }
  }

  const handleBack = () => {
    setNext(true)
  }

  const handleNext = async() => {
    const Addifee = AdditionalFee === 'Yes'?false:additionalFieldValidation
    const otherField = Addifee ? (OtherFields?.length):true
    const FreeTxt = Addifee ? (FreeText?.length):true
    const SchedleC = Addifee ? (ScheduleC?.length):true
    const SchedleB = AdditionalFee === 'Yes' ? (ScheduleB?.length):true

    const LarSeed = allowLargeSeed ? ( largeSeed?.length ) : true
    const SmlSeed = allowSmallSeed ? ( smallSeed?.length ) : true


    if (Next && Entity && AdditionalFee && otherField && SubmittedBy) {
      setNext(false)
    } else {
      if (
        Entity && AdditionalFee 
        && otherField && SubmittedBy 
        && FreeTxt && SchedleC 
        && SchedleB && LarSeed && SmlSeed ) {
        const updateApi = apiURL.ppp.createDocument ?? "";
        const userDetaisl = Authorization.authUser;
        const createDocApi = util.format(updateApi, userDetaisl.id);
        const reqBody = await CreateDocSchema();
        dispatch(updatecreateDocument({ url: createDocApi, body: reqBody }))
      } else {
        setError(true)
      }
    }
  }


  useEffect(() => {
  if (getDownloadFilePathError === true && !getDownloadFilePath?.body) 
   {
    const errormsg = getDownloadFilePath?.response?.data?.message;
    const noNetworkMessage = ERROR_MSG;
    const msg = errormsg?.length > 0 ? errormsg : noNetworkMessage;
    dispatch(getFilePathresetStatus())
    popUpSuccessr(msg, "error");

  }else if (getDownloadFilePath?.status === SUCCESS) {
    // window.removeEventListener('beforeunload',handleTabClose)
    dispatch(getFilePathresetStatus())
    popUpSuccessr('file download has been started', "success");
    const path = getDownloadFilePath?.body?.s3url
      const a = document.createElement('a')
      a.href = path
      // a.setAttribute("download", 'sample.pdf');
      a.download = path
      // event.preventDefault();
      a.target = '_self'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      // window.addEventListener('beforeunload',handleTabClose)

  }

  }, [getDownloadFilePathStatus,getDownloadFilePath]);


  useEffect(() => {
    if (submitDocumentError === true && !submitDocumentDetails?.body) {
      const errormsg = submitDocumentDetails?.response?.data?.message;
      const noNetworkMessage = ERROR_MSG;
      const msg = errormsg?.length > 0 ? errormsg : noNetworkMessage;
      dispatch(resetStatus())
      popUpSuccessr(msg, "error");
    } else if (submitDocumentDetails?.status === SUCCESS) {
      setDocCreate(true)
      popUpSuccessr(submitDocumentDetails?.message, "success");
      setSavedFilePath(submitDocumentDetails?.body?.file?.fileKey??'')
      getDocLoad(submitDocumentDetails?.body?.file?.fileKey)
      dispatch(resetStatus())
    }
  }, [submitDocumentStatus, submitDocumentDetails]);

  const getDocLoad = (fileKey) => {
    const updateApi = apiURL.ppp.getDocument ?? "";
    const userDetaisl = Authorization.authUser;
    const createDocApi = util.format(updateApi, userDetaisl.id);
    setPdfUrl('')
    dispatch(getDocumentData(createDocApi + fileKey || ''))
  }

  const handleBoldFrmKbd=(e)=>{
    if(e.keyCode===13 && e.ctrlKey){
    } 
  }
  
  useEffect(() => {
    DocCreate && getDocLoad(rowData?.itmObj?.contractfile || '')
    document.addEventListener('keydown',handleBoldFrmKbd);

  }, [])

  useEffect(() => {
    if (saveDocumentError === true && !saveDocumentDetails?.body) {
      const errormsg = saveDocumentDetails?.message;
      const noNetworkMessage = ERROR_MSG;
      const msg = saveDocumentDetails?.message ? errormsg : noNetworkMessage;
      // dispatch(resetDocument())
      popUpSuccessr(msg, "error");
    } else if (saveDocumentDetails?.status === SUCCESS) {
      props.refreshAction(true)
      setSaved(true)
      // printDoc()
    }
  }, [saveDocumentStatus, saveDocumentDetails]);


  useEffect(() => {
    if (getDocumentError === true && !getDocumentDetails?.body) {
      const errormsg = getDocumentDetails?.message;
      const noNetworkMessage = ERROR_MSG;
      const msg = getDocumentDetails?.message ? errormsg : noNetworkMessage;
      dispatch(resetDocument())
      popUpSuccessr(msg, "error");
    } else if (getDocumentDetails?.status === SUCCESS) {
      createDocument(getDocumentDetails?.body?.data)
      dispatch(resetDocument())
    }
  }, [getDocumentStatus, getDocumentDetails]);


  useEffect(()=>{
    const handleTabClose = event => {

      event.preventDefault();
      return(event.returnValue = 'Are you want to exit?');
      
    }
    if(getDownloadFilePath?.status === SUCCESS)
    {
      window.removeEventListener('beforeunload',handleTabClose)
    }else{
      window.addEventListener('beforeunload',handleTabClose)
    }
    
     return()=>{
       window.removeEventListener('beforeunload',handleTabClose)
     }    
  },[getDownloadFilePath])


  const createDocument = async (resp) => {
    const byteKeys = await Object.keys(resp).map(Number).sort((a, b) => a - b);
    const maxKey = byteKeys&&byteKeys.pop()
    // Math.max(...byteKeys)
    // console.log("getMaKey",maxKey,byteKeys.pop())
     const arr = new Uint8Array(maxKey + 1)
     byteKeys.forEach(key => {
         arr[key] = resp[key]
     })
     const blob = new Blob([arr], { type: 'application/pdf' })
     const pdfurl = await URL.createObjectURL(blob)
   setPdfUrl(pdfurl)
  }
 
  const makeBold =(refId)=>{
    const src = {'OTF':OtherFields,'FT':FreeText,'SB':ScheduleB,'SC':ScheduleC}
    var tempSd = src[refId]
    const selstr = window.getSelection().toString()
    // console.log("finderissue",selstr,refId,document.getElementById(refId))
    const selRange = (document.getElementById(refId).selectionStart);
    // console.log("finderissue2",selRange,refId)

    const selRangeend = (document.getElementById(refId).selectionEnd);
    if(selstr){ 
    const sbrstr = tempSd.substring(selRange-(breakOpenLength+underlineOpenLength), selRange);
    var replOthr = ''
    if(sbrstr.includes('<b><u>')||sbrstr.includes('<u><b>'))
    {
      replOthr = tempSd.slice(0,selRange-(breakOpenLength+underlineOpenLength)) + `<u>${selstr}</u>` +tempSd.slice(selRangeend+(breakCloseLength+underLineCloseLength))
    }else if(sbrstr.includes('<b>')){
      replOthr = tempSd.slice(0,selRange-(breakOpenLength)) + selstr +tempSd.slice(selRangeend+(breakCloseLength),tempSd?.length)
    }else{
      replOthr = tempSd.slice(0,selRange) + `<b>${selstr}</b>` +tempSd.slice(selRangeend, tempSd?.length)
    }
    if(refId === 'OTF'){
    setOtherFields(replOthr)
    }else if(refId === 'FT'){
      setFreeText(replOthr)
    }else if(refId === 'SB'){
      setScheduleB(replOthr)
    }else{
      setScheduleC(replOthr)
    }
    }
  }


  const makeUnderline =(refId)=>{
    const src = {'OTF':OtherFields,'FT':FreeText,'SB':ScheduleB,'SC':ScheduleC}
    var tempSd = src[refId]
    const selRange = (document.getElementById(refId).selectionStart);
    const selRangeend = (document.getElementById(refId).selectionEnd);
    const selstr = window.getSelection().toString() 
    if(selstr){
    const sbrstr = tempSd.substring(selRange-(breakOpenLength+underlineOpenLength), selRange);
    var replOthr = ''
    if(sbrstr.includes('<b><u>')||sbrstr.includes('<u><b>'))
    {
      replOthr = tempSd.slice(0,selRange-(breakOpenLength + underlineOpenLength)) + `<b>${selstr}</b>` +tempSd.slice(selRangeend+(breakCloseLength+underLineCloseLength))
    }else if(sbrstr.includes('<u>')){
      if(sbrstr.includes('<b>')){
        replOthr = tempSd.slice(0,selRange) + `<u>${selstr}</u>` +tempSd.slice(selRangeend,tempSd?.length)
      }else{
      replOthr = tempSd.slice(0,selRange-underlineOpenLength) + selstr + tempSd.slice(selRangeend+underLineCloseLength,tempSd?.length)
      }
    }else{
      replOthr = tempSd.slice(0,selRange) + `<u>${selstr}</u>` +tempSd.slice(selRangeend, tempSd?.length)
    }
    if(refId === 'OTF'){
      setOtherFields(replOthr)
      }else if(refId === 'FT'){
        setFreeText(replOthr)
      }else if(refId === 'SB'){
        setScheduleB(replOthr)
      }else{
        setScheduleC(replOthr)
      }
   }
  }



  const popUpSuccessr = (msg, type) => {
    openSnackbar({
      message: msg,
      variant: type,
    });
  };
  const InitialSubmissin = () => {
    let currentDate = new Date().toJSON().slice(0, 10);
    const entityName = props?.rowData?.itmObj?.entityname ?? 'Bayer LLC'
    return (
      <Fragment>
        <MDTextField enable={true} Error={Error && (Entity.length === 0)} value={entityName} updatedValue={(value) => UpdateEntity(value)} title={ENTITY} width={'100%'} />
        <MDDateField max={currentDate} width={'150px'} Error={Error &&(SubmittedBy.length === 0)} fontSize={'11px !important'} paddingtop={1} value={SubmittedBy} UpdateSubmittedBy={(value) => UpdateSubmittedBy(value)} title={SUBMITTEDBY} />
        {/* <MDTextField  Error={AdditionalFee === 'Yes'?(Error && (OtherFields.length === 0)):false} value={OtherFields} updatedValue={(value) => UpdateOtherFields(value)} title={OTHERFIELDS} width={'100%'} /> */}
       <div style={{marginTop:20}}>
         <Paper elevation={0} style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}><Paper  elevation={0} style={{width:'50%'}}><Typography style={{fontSize:'11px',fontWeight:500}}>{OTHERFIELDS}</Typography></Paper>
         <Paper  elevation={0} style={{width:'49%',display:'flex',justifyContent:'flex-end',paddingTop:2,paddingBottom:2}}>
        {/* <Button variant="outlined" disableRipple={true}  style={{marginRight:10,minWidth:20,height:20,fontSize:10,backgroundColor:'lightgrey'}} onClick={()=>makeBold('OTF',OtherFields)}>B</Button> */}
         {/* <Button variant="outlined" disableRipple={true} style={{minWidth:20,height:20,fontSize:10,backgroundColor:'lightgrey'}} onClick={()=>makeUnderline('OTF',OtherFields)}>U</Button> */}
         </Paper></Paper>
         <DocumentTextArea value={OtherFields} onChange={setOtherFields}/>
         {/* <textarea id='OTF' value={OtherFields} onChange={(e) => UpdateOtherFields(e.target.value)} style={{padding:5,borderRadius:5,borderColor:(additionalFieldValidation && AdditionalFee === 'No'?(Error && (OtherFields.length === 0)):false)?'red':'#E0E3E7',outline:'transparent',width:'100%',minHeight:120,marginRight:20,marginBottom:20}} /> */}
       </div>
       <AdditionalServiceFee Error={Error && (AdditionalFee.length === 0)} value={AdditionalFee} updateValue={(value) => UpdateAdditionalFee(value)} />
      </Fragment>
    )
  }
  const allowSmallSeedVarieties = () => {
    setAllowSmallSeed(!allowSmallSeed)
  }
  const allowLargeSeedVarietiest = () => {
    setAllowLargeSeed(!allowLargeSeed)
  }
  const FinalSubmissin = (item) => {
    return (
      <Fragment>
        {/* <MDTextField value={FreeText} updatedValue={(value) => UpdateFreeText(value)} title={FREETEXT} width={'100%'} /> */}
        {/* <MDTextField value={ScheduleB} updatedValue={(value) => UpdateScheduleB(value)} title={SCHEDULEB} width={'100%'} /> */}
        {/* <Box> */}
         {/* <Typography style={{fontSize:'11px',fontWeight:500}}>{FREETEXT}</Typography> */}
         {/* <Paper elevation={0} style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}><Paper  elevation={0} style={{width:'50%'}}><Typography style={{fontSize:'11px',fontWeight:500}}>{FREETEXT}</Typography></Paper> */}
         {/* <Paper  elevation={0} style={{width:'49%',display:'flex',justifyContent:'flex-end',paddingTop:2,paddingBottom:2}}> */}
        {/* <Button variant="outlined" disableRipple={true}  style={{marginRight:10,minWidth:20,height:20,fontSize:10,backgroundColor:'lightgrey'}} onClick={()=>makeBold('FT')}>B</Button> */}
         {/* <Button variant="outlined" disableRipple={true} style={{minWidth:20,height:20,fontSize:10,backgroundColor:'lightgrey'}} onClick={()=>makeUnderline('FT')}>U</Button> */}
         {/* </Paper></Paper> */}
         {/* <DocumentTextArea hyt={120} value={FreeText} onChange={setFreeText}/> */}
         {/* <textarea id='FT' value={FreeText}  onChange={(e) => UpdateFreeText(e.target.value)} style={{padding:5,borderRadius:5,borderColor:(additionalFieldValidation && AdditionalFee === 'No'?(Error && (FreeText.length === 0)):false)?'red':'#E0E3E7',outline:'transparent',width:'99%',minHeight:80,marginRight:20,marginBottom:20}} /> */}
       {/* </Box> */}
       {!isAmericanCountry&&<Paper elevation={0} style={{display:'flex',justifyContent:'flex-start',alignItems:'center',paddingTop:5}}><Paper  elevation={0} style={{width:'50%',paddingTop:8}}><Typography style={{fontSize:'11px',fontWeight:500,fontWeight:600}}>{'Is the PPP program applicable to :'}</Typography></Paper></Paper>}
       {!isAmericanCountry&&<Paper elevation={0} style={{paddingLeft:10,backgroundColor:'white',borderRadius:0,display:'flex',alignItem:'left',justifyContent:'left',paddingTop:2,paddingBottom:2}}>
            <FormGroup >
              <FormControlLabel style={{height:40,paddingTop:9}}  control={<Checkbox onChange={()=>allowSmallSeedVarieties()} disabled={false} checked={allowSmallSeed} style={{width:12,height:12,paddingTop:6}} />} label={<Typography style={{fontSize:10,letterSpacing:0.5,fontWeight:500,paddingBottom:9,paddingLeft:5,paddingTop:5}}> Add Small Seeds ( select only if applicable to specific crops).</Typography>}/>
            </FormGroup>
       </Paper>}
       {allowSmallSeed&&<Box>
         <Paper elevation={0} style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}><Paper  elevation={0} style={{width:'50%'}}><Typography style={{fontSize:'11px',fontWeight:500}}>{SMALLSEED}</Typography></Paper>
         <Paper  elevation={0} style={{width:'49%',display:'flex',justifyContent:'flex-end',paddingTop:2,paddingBottom:2}}>
         </Paper></Paper>
         <textarea id='ss' value={smallSeed} onChange={(e) => setSmallSeed(e.target.value)} style={{padding:5,borderRadius:5,borderColor:Error && allowSmallSeed &&(smallSeed.length === 0)?'red':'lightgrey',outline:'transparent',width:'100%',minHeight:80,marginRight:20,marginBottom:20}} /> 

         {/* <DocumentTextArea clr= {Error && allowSmallSeed &&(smallSeed.length === 0)?'red':'lightgrey'} hyt={120} value={smallSeed} onChange={setSmallSeed}/> */}
       </Box>}
      {!isAmericanCountry&&<Paper elevation={0} style={{paddingLeft:10,backgroundColor:'white',borderRadius:0,display:'flex',alignItem:'left',justifyContent:'left',paddingTop:2,paddingBottom:2}}>
            <FormGroup >
              <FormControlLabel style={{height:40,paddingTop:9}}  control={<Checkbox onChange={()=>allowLargeSeedVarietiest()} disabled={false} checked={allowLargeSeed} style={{width:12,height:12,paddingTop:6}} />} label={<Typography style={{fontSize:10,letterSpacing:0.5,fontWeight:500,paddingBottom:9,paddingLeft:5,paddingTop:5}}> Add Large Seeds ( select only if applicable to specific crops ).</Typography>}/>
            </FormGroup>
       </Paper>}
       {allowLargeSeed&&<Box>
         <Paper elevation={0} style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}><Paper  elevation={0} style={{width:'50%'}}><Typography style={{fontSize:'11px',fontWeight:500}}>{LARGESEED}</Typography></Paper>
         <Paper  elevation={0} style={{width:'49%',display:'flex',justifyContent:'flex-end',paddingTop:2,paddingBottom:2}}>
         </Paper></Paper>
         <textarea id='ls' value={largeSeed} onChange={(e) => setLargeSeed(e.target.value)} style={{padding:5,borderRadius:5,borderColor:Error && allowLargeSeed &&(largeSeed.length === 0)?'red':'lightgrey',outline:'transparent',width:'100%',minHeight:80,marginRight:20,marginBottom:20}} /> 
         {/* <DocumentTextArea clr= {Error && allowLargeSeed &&(largeSeed.length === 0)?'red':'lightgrey'} hyt={120} value={largeSeed} onChange={setLargeSeed}/> */}
       </Box>}
       <Box>
         {/* <Typography style={{fontSize:'11px',fontWeight:500}}>{SCHEDULEB}</Typography> */}
         <Paper elevation={0} style={{display:'flex',justifyContent:'flex-start',alignItems:'center',paddingTop:5}}><Paper  elevation={0} style={{width:'50%',paddingTop:8}}><Typography style={{fontSize:'11px',fontWeight:500}}>{SCHEDULEB}</Typography></Paper>
         <Paper  elevation={0} style={{width:'49%',display:'flex',justifyContent:'flex-end',paddingBottom:2}}>
        {/* <Button variant="outlined" disableRipple={true}  style={{marginRight:10,minWidth:20,height:20,fontSize:10,backgroundColor:'lightgrey'}} onClick={()=>makeBold('SB')}>B</Button> */}
         {/* <Button variant="outlined" disableRipple={true} style={{minWidth:20,height:20,fontSize:10,backgroundColor:'lightgrey'}} onClick={()=>makeUnderline('SB')}>U</Button> */}
         </Paper></Paper>
         <DocumentTextArea hyt={220} value={ScheduleB} onChange={setScheduleB}/>
         {/* <textarea id='SB' value={ScheduleB} onChange={(e) => UpdateScheduleB(e.target.value)} style={{padding:5,borderRadius:5,borderColor:((AdditionalFee === 'Yes' || additionalFieldValidation ) && Error && (ScheduleB.length === 0))?'red':'#E0E3E7',outline:'transparent',width:'99%',minHeight:80,marginRight:20,marginBottom:20}} /> */}
       </Box>
       {/* <Box> */}
         {/* <Typography style={{fontSize:'11px',fontWeight:500}}>{SCHEDULEC}</Typography> */}
         {/* <Paper elevation={0} style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}><Paper  elevation={0} style={{width:'50%'}}><Typography style={{fontSize:'11px',fontWeight:500}}>{SCHEDULEC}</Typography></Paper>
         <Paper  elevation={0} style={{width:'49%',display:'flex',justifyContent:'flex-end',paddingTop:2,paddingBottom:2}}>
        <Button variant="outlined" disableRipple={true}  style={{marginRight:10,minWidth:20,fontSize:10,height:20,backgroundColor:'lightgrey'}} onClick={()=>makeBold('SC')}>B</Button>
         <Button variant="outlined" disableRipple={true} style={{minWidth:20,height:20,fontSize:10,backgroundColor:'lightgrey'}} onClick={()=>makeUnderline('SC')}>U</Button>
         </Paper></Paper>
         <DocumentTextArea value={ScheduleC} onChange={setScheduleC}/> */}
         {/* <textarea id='SC' value={ScheduleC} onChange={(e) => UpdateScheduleC(e.target.value)} style={{padding:5,borderRadius:5,borderColor:(additionalFieldValidation && AdditionalFee === 'No'?(Error && (ScheduleC.length === 0)):false)?'red':'#E0E3E7',outline:'transparent',width:'99%',minHeight:80,marginRight:20,marginBottom:20}} /> */}
       {/* </Box> */}
      </Fragment>
    )
  }
  const BayerSideAccept = () => {
    setAcceptTc(!acceptTc)
  } 
  const DocumentComplete = () => {
    /**
        Upcoming Implementation
  */
    /* const [authCheck,SetAuthCheck] = useState(false)
    const handleAuthCheck = () => {
      SetAuthCheck(!authCheck)
    } */
    return (
      <Fragment>
        {(getDownloadFilePathStatus || saveDocumentStatus) && <Loader />}
        <Box style={{ maxHeight: '90vh' }} sx={styledoc}>
          <Paper style={{ backgroundColor: 'transparent', color: 'white', height: '15%', ...useStyles.close }} elevation={0}>
            <Typography style={{ color: 'black', width: 20, height: 20, borderRadius: 10, fontSize: 10, marginBottom: 10, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }} component='div'>
              < Button onClick={() => handleClose()} style={{ fontSize: 10 }} disableRipple={true}>X</Button>
            </Typography>
          </Paper>
          <Paper style={{ height: '85vh', overflow: 'auto', width: 1000 }} sx={{ p: 2, boxshadow: 24, }}>
            <Paper elevation={0} style={{ ...useStyles.mainroot }}>
              <Typography style={{ display: 'flex', letterSpacing: 0.3,justifyContent: 'start', fontSize: '14px', alignItems: 'center', fontWeight: 800, ...useStyles.onerowfirst }}>{TITLE}</Typography>
              {
                /**
                 Upcoming Implementation
                
                /*<Paper style={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent:'flex-end',
                    width:'50%',
                            
                  }}   elevation={0}   >
                    <FormControlLabel
                  control={
                    <Checkbox checked={authCheck} onChange={handleAuthCheck} name="authcheck" />
                  }
                  label= {AUTHORIZATION}
                />          
           </Paper> */
  }
            </Paper>
            <Paper elevation={0} sx={{ pl: 2, mt: 2 }} style={{ height: '65%', overflow: 'auto' }}>
              {/* <DocumentCreate_otc rowData={rowData||[]} ref={ref} /> */}
              {/* {pdfUrl && <iframe src={pdfUrl} />} */}
              {pdfUrl ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer defaultScale={1.5} fileUrl={pdfUrl} onDocumentLoad={()=>{setLoading(false)}} />
              </Worker> : <Box style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <CircularProgress thickness={1.5} style={{ height: 65, width: 65, color: 'grey' }} />
              </Box>
              }
            </Paper>
            {/* <Paper id={'printabledocument'} elevation={0} sx={{ pl: 2, mt: 2 }} style={{ height: '75%', overflow: 'auto' }}>
            <Typography style={{ fontSize: 12 }} gutterBottom>
              <Typography style={{ fontSize: 12, fontWeight: 600 }}>What is Loren ipsum ?</Typography>
                    Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                    consectetur ac, vestibulum at eros.
                   <br />
                    Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                    consectetur ac, vestibulum at eros.
                  </Typography>
            <Typography style={{ fontSize: 12 }} gutterBottom>
              <Typography style={{ fontSize: 12, fontWeight: 600 }}>Why do we use it ?</Typography>
                    Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                    consectetur ac, vestibulum at eros.
                    <br />
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                    Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                  </Typography>
            <Typography style={{ fontSize: 12, marginTop: 10 }} gutterBottom>
              <Typography style={{ fontSize: 12, fontWeight: 600 }}>Where does it come from ?</Typography>
                    Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
                    magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
                    ullamcorper nulla non metus auctor fringilla.<br />
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                    Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                  </Typography>
            <Typography style={{ fontSize: 12, marginTop: 10 }} gutterBottom>
              <Typography style={{ fontSize: 12, fontWeight: 600 }}>egestas eget quam. Morbi leo risus</Typography>
                    Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
                    magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
                    ullamcorper nulla non metus aufctor fringilla.
                  </Typography>
          </Paper> */}  

              <Paper elevation={0} style={{paddingLeft:20,backgroundColor:'white',borderRadius:0,display:'flex',alignItem:'center',justifyContent:'center',paddingTop:2,paddingBottom:2}}>
            {(!(saved))&&<FormGroup >
              <FormControlLabel style={{height:40,paddingTop:9}}  control={<Checkbox onChange={()=>BayerSideAccept()} disabled={isLoading} checked={acceptTc} style={{width:12,height:12,paddingTop:6}} />} label={<Typography style={{fontSize:10,letterSpacing:0.5,fontWeight:600,paddingBottom:9,paddingLeft:5,paddingTop:5}}> I confirm that this is the final version of the document.</Typography>}/>
            </FormGroup>}
            </Paper>
             <Paper elevation={0} style={{ width: '100%', height: 'auto', marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {!(saved)&&<Button variant="outlined" onClick={() => props.editAction()} disabled={isLoading} disableRipple={true} style={{ marginRight: 20, borderRadius: 25, height: 40, width: 140, textTransform: 'none', fontSize: 11 }} >
                {ACTION_EDIT}
              </Button>}
              {(!(saved))&&<Button className='cus-btn-arrow' onClick={() => SaveandPrint()} disabled={isLoading || !acceptTc} disableRipple={true} style={{ paddingLeft:'2px',marginRight: 20, borderRadius: 25, height: 40, width: 140, textTransform: 'none', fontSize: 11 }} variant="contained">
                {ACTION_SAVE}
              </Button>}
              {(saved)&&<Button className="cus-btn-arrow" onClick={() => DownloadDoc()} disabled={isLoading && acceptTc} disableRipple={true} style={{ borderRadius: 25, height: 40, width: 140, textTransform: 'none', fontSize: 11, paddingLeft:'2px' }} variant="contained">
                {ACTION_DOWNLOAD}
              </Button>}
              {(saved)&&<Button className="cus-btn-arrow" onClick={() => PrintSavedDoc()} disabled={isLoading} disableRipple={true} style={{ left:10,borderRadius: 25, height: 40, width: 140, textTransform: 'none', fontSize: 11, paddingLeft:'5px' }} variant="contained">
                {ACTION_PRINT}
              </Button>}
              {!(saved)&&<Button className="cus-btn-arrow" onClick={() => goToBack()} disabled={false} disableRipple={true} style={{ left:10,borderRadius: 25, height: 40, width: 140, textTransform: 'none', fontSize: 11, paddingLeft:'5px' }} variant="contained">
                {'Back'}
              </Button>}
            </Paper>
          </Paper>
        </Box>
      </Fragment>
    )
  }


  const printDoc = () => {

    if (pdfUrl) {
      const printWindow = window.open(pdfUrl, '', 'height=500, width=800')
      printWindow.onload = () => {
        printWindow.print();
      }
    }
  }
  const DownloadDoc =()=>{
    const updateApi = apiURL.ppp.DownloadFileDocument ?? "";
    const userDetaisl = Authorization.authUser;
    const filepath = savedFilePath ? savedFilePath :(rowData?.itmObj?.contractfile)
    const createDocApi = util.format(updateApi, userDetaisl.id) + (filepath || '') + '&url=true';
    dispatch(getFilePath(createDocApi))

    // const updateApi = BASE_API_PATH + apiURL.ppp.DownloadFileDocument ?? "";
    // const userDetaisl = Authorization.authUser;
    // const createDocApi = util.format(updateApi, userDetaisl.id) + (rowData?.itmObj?.contractfile || '') + '&url=true';
    //   const a = document.createElement('a')
    //   a.href = createDocApi
    //   // a.download = createDocApi.split('/').pop()
    //   document.body.appendChild(a)
    //   a.click()
    //   document.body.removeChild(a)
  }

  const PrintSavedDoc =()=>{
    printDoc()
  }

  const SaveandPrint = () => {

    const updateApi = apiURL.ppp.saveDocument ?? "";
    const userDetaisl = Authorization.authUser;
    const createDocApi = util.format(updateApi, userDetaisl.id);
    const reqBody = SaveDocSchema();
    dispatch(saveDocumentData({ url: createDocApi, body: reqBody }))


    // ref.current.printDocument();

    // var divContents = document.getElementById("printabledocument").innerHTML;
    // var a = window.open('', '', 'height=500, width=500');
    // a.document.write('<html>');
    // a.document.write('<body>');
    // a.document.write('<div style="font-weight:600;color:black;font-size:14;display:flex;align-items:center;justify-content:center">Document Name</div>');
    // a.document.write(divContents);
    // a.document.write('</body></html>');
    // a.document.close();
    // a.print();
  }

  const goToBack = () => {
    setDocCreate(false)
    setNext(false)
  }

  const DocumentProgress = () => {
    return (
      <Fragment>
        {(submitDocumentStatus || getDocumentStatus || saveDocumentStatus) && <Loader />}
        <Box sx={style}>
          <Paper style={{ backgroundColor: 'transparent', color: 'white', height: '15%', ...useStyles.close }} elevation={0} >
            <Typography style={{ color: 'black', width: 20, height: 20, borderRadius: 10, fontSize: 10, marginBottom: 10, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }} component='div'>
              < Button onClick={() => handleClose()} style={{ fontSize: 10 }} disableRipple={true}>X</Button>
            </Typography>
          </Paper>
          <Paper style={{ maxHeight: '90vh', overflowY: 'auto' }} sx={{ p: 2, boxshadow: 24 }}>
            <Paper elevation={0} style={{ ...useStyles.mainroot }}>
              <Typography style={{ display: 'flex', alignItems: 'center', fontWeight: 600, ...useStyles.onerowfirst }} >{TITLE}</Typography>
              {/* <Button onClick={() => handleSkip()} style={{ display: 'flex', fontWeight: 600, textTransform: 'none',...useStyles.onerowsecond }}>{SKIP}</Button> */}
            </Paper>
            <Paper elevation={0} style={{ height: '75%', backgroundColor: 'transparent' }}>{Next ? InitialSubmissin() : FinalSubmissin()}</Paper>
            <Box style={{width:'100%',displya:'flex',flexDirection:'row'}}>
            <Paper elevation={0} style={{ maxHeight: '90vh', overflowY: 'scroll', width: '100%', height: 'auto', marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {!Next&&<Button className="cus-btn-arrow" onClick={() => handleBack()} disableRipple={true} style={{ marginRight:'10px',paddingRight:'40px',borderRadius: 25, height: 30, width: 100, textTransform: 'none', fontSize: 11 }} variant="contained">
                {'Back'}
              </Button>}
              <Button className="cus-btn-arrow" onClick={() => handleNext()} disableRipple={true} style={{paddingRight:'40px',borderRadius: 25, height: 30, width: 100, textTransform: 'none', fontSize: 11 }} variant="contained">
                {ACTION_NEXT}
              </Button>
            </Paper>
            </Box>
          </Paper>
        </Box>
      </Fragment>

    )
  }

  const ok = () => {
    setMiModalShow(false)
    props.close(props.set)
  }
  const cancel = () => {
    setMiModalShow(false)
  }
  
  return (
    <div>
      <Modal
        open={open}
        onClose={()=>{}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!DocCreate ? DocumentProgress() : DocumentComplete()}
      </Modal>
      <>
            <MiModal open={((SubmittedBy.length !== 0 || OtherFields.length !== 0) && miModalShow && !saved )}
                title="Confirmation"
                description={CONTRACT_DOCUMENT_POPUP_MESSAGE} 
                ok={{ buttonName: 'Proceed', ok: ok }}
                cancel={{ buttonName: 'Cancel', cancel: cancel }} />

        </>
    </div>
  );
}