const activityNameHeader = {
  id: "activity_name__c",
  label: "Activity Name",
  numeric: false,
  disablePadding: true,
  minWidth: "auto",
  isInputBox: false,
  isLast: false,
  userInteration: false,
  isMaxWidthApply: false,
};
const datOfEventHeader = {
  id: "date__c",
  label: "Date of Event",
  type: "date",
  numeric: false,
  disablePadding: false,
  minWidth: "auto",
  isInputBox: true,
  isLast: false,
  userInteration: false,
};
const participantsHeader = {
  id: "no_of_participants__c",
  label: "Participants",
  numeric: true,
  disablePadding: true,
  minWidth: "auto",
  isInputBox: false,
  isLast: false,
  userInteration: false,
  isMaxWidthApply: false,
};

const locationHeader = {
  id: "event_location__c",
  label: "Location",
  numeric: false,
  disablePadding: false,
  minWidth: "auto",
  isInputBox: false,
  isLast: false,
  userInteration: false,
  isMaxWidthApply: false,
};

const descriptionHeader = {
  id: "description__c",
  label: "Description",
  numeric: false,
  disablePadding: true,
  minWidth: "auto",
  isInputBox: false,
  isLast: false,
  userInteration: false,
  isMaxWidthApply: false,
};

const activityLinkHeader = {
  id: "link__c",
  label: "Activity Link",
  numeric: false,
  disablePadding: true,
  minWidth: "auto",
  isInputBox: false,
  isLast: false,
  userInteration: false,
  isMaxWidthApply: false,
};

const consentToPublishHeader = {
  id: "consent_to_publish__c",
  label: "Consent to Publish",
  numeric: false,
  disablePadding: true,
  minWidth: "auto",
  isInputBox: false,
  isLast: false,
  userInteration: false,
  isMaxWidthApply: false,
};
const statusHeader = {
  id: "status__c",
  label: "Status",
  numeric: false,
  disablePadding: false,
  minWidth: "auto",
  isInputBox: false,
  isLast: false,
  userInteration: false,
  isMaxWidthApply: false,
};

const photosHeader = {
  id: "photos",
  label: "Attached File",
  numeric: false,
  disablePadding: false,
  minWidth: "auto",
  isInputBox: false,
  isLast: false,
  userInteration: false,
  isMaxWidthApply: false,
};
const actionHeader = {
  id: "action",
  label: "Action",
  numeric: false,
  disablePadding: false,
  minWidth: "auto",
  isInputBox: false,
  isLast: false,
  userInteration: true,
  isMaxWidthApply: false,
};

/**ONLINE MARKETING ACTIVITIES */
/* Online Group 1 (5)*/
//Social Media
const socialMediaHdr = [activityNameHeader, datOfEventHeader, actionHeader];
const socialMediaCollapseHdr = [activityLinkHeader, consentToPublishHeader, statusHeader];

//Paid Media
const paidMediaHdr = [activityNameHeader, datOfEventHeader, actionHeader];
const paidMediaCollapseHdr = [activityLinkHeader, consentToPublishHeader, statusHeader];

//Website
const websiteHdr = [activityNameHeader, datOfEventHeader, actionHeader];
const websiteCollapseHdr = [activityLinkHeader, consentToPublishHeader, statusHeader];

//Content Creation
const contentCreationHdr = [activityNameHeader, datOfEventHeader, actionHeader];
const contentCreationCollapseHdr = [activityLinkHeader, consentToPublishHeader, statusHeader];

//Video Content
const videoContentHdr = [activityNameHeader, datOfEventHeader, actionHeader];
const videoContentCollapseHdr = [activityLinkHeader, consentToPublishHeader, statusHeader];

/* Online Group 2(1)*/
//Newsletter
const newsLetterHdr = [activityNameHeader, statusHeader, actionHeader];
const newsLetterCollapseHdr = [descriptionHeader, activityLinkHeader];

/* Online Group 3(1)*/
//Other Digital Marketing
const otherDigitalMarketingHdr = [activityNameHeader, datOfEventHeader, activityLinkHeader, actionHeader];
const otherDigitalMarketingCollapseHdr = [descriptionHeader];

/* Online Group 4(1)*/
//Online Event
const onlineEventHdr = [activityNameHeader, datOfEventHeader, participantsHeader, actionHeader];
const onlineEventCollapseHdr = [descriptionHeader, activityLinkHeader, consentToPublishHeader, statusHeader, photosHeader];
//=====================================================

/**OFFLINE MARKETING ACTIVITIES */
/**Offline Group 1 */
//Field Day
const fieldDayHdr = [activityNameHeader, datOfEventHeader, participantsHeader, locationHeader, actionHeader];
const fieldDayCollapseHdr = [descriptionHeader, activityLinkHeader, consentToPublishHeader, statusHeader, photosHeader];

//Fair Expo
const fairExpoHdr = [activityNameHeader, datOfEventHeader, participantsHeader, locationHeader, actionHeader];
const fairExpoCollapseHdr = [descriptionHeader, activityLinkHeader, consentToPublishHeader, statusHeader, photosHeader];

//Large Scale
const largeScaleHdr = [activityNameHeader, datOfEventHeader, participantsHeader, locationHeader, actionHeader];
const largeScaleCollapseHdr = [descriptionHeader, activityLinkHeader, consentToPublishHeader, statusHeader, photosHeader];

//Small Scale
const smallScaleHdr = [activityNameHeader, datOfEventHeader, participantsHeader, locationHeader, actionHeader];
const smallScaleCollapseHdr = [descriptionHeader, activityLinkHeader, consentToPublishHeader, statusHeader, photosHeader];

//Field Visit
const fieldVisitHdr = [activityNameHeader, datOfEventHeader, participantsHeader, locationHeader, actionHeader];
const fieldVisitCollapseHdr = [descriptionHeader, activityLinkHeader, consentToPublishHeader, statusHeader, photosHeader];

//Seminar
const seminarHdr = [activityNameHeader, datOfEventHeader, participantsHeader, locationHeader, actionHeader];
const seminarCollapseHdr = [descriptionHeader, activityLinkHeader, consentToPublishHeader, statusHeader, photosHeader];

//Meeting
const meetingHdr = [activityNameHeader, datOfEventHeader, participantsHeader, locationHeader, actionHeader];
const meetingCollapseHdr = [descriptionHeader, activityLinkHeader, consentToPublishHeader, statusHeader, photosHeader];

/**Offline Group 2 */
//Merchandize
const merchandizeHdr = [activityNameHeader, datOfEventHeader, actionHeader];
const merchandizeCollapseHdr = [descriptionHeader, statusHeader, photosHeader];

//Printed Material
const printedMaterialHdr = [activityNameHeader, datOfEventHeader, actionHeader];
const printedMaterialCollapseHdr = [descriptionHeader, statusHeader, photosHeader];

//Other
const otherHdr = [activityNameHeader, datOfEventHeader, actionHeader];
const otherCollapseHdr = [descriptionHeader, statusHeader, photosHeader];

//Radio/Tel
const RadioTelHdr = [activityNameHeader, datOfEventHeader, actionHeader];
const RadioTelCollapseHdr = [descriptionHeader, statusHeader, photosHeader];

//SMS
const smsHdr = [activityNameHeader, datOfEventHeader, actionHeader];
const smsCollapseHdr = [descriptionHeader, statusHeader, photosHeader];

export const offlineHeaders = [
  { typeName: "fair expo", headers:fairExpoHdr, collapseH:fairExpoCollapseHdr },
  { typeName: "field day", headers:fieldDayHdr, collapseH: fieldDayCollapseHdr },
  { typeName: "field visit", headers:fieldVisitHdr, collapseH:fieldVisitCollapseHdr },
  { typeName: "large scale field day", headers:largeScaleHdr, collapseH:largeScaleCollapseHdr },
  { typeName: "small scale field day", headers:smallScaleHdr, collapseH:smallScaleCollapseHdr },
  { typeName: "meeting", headers:meetingHdr, collapseH:meetingCollapseHdr },
  { typeName: "merchandize", headers:merchandizeHdr, collapseH:merchandizeCollapseHdr },
  { typeName: "seminar", headers:seminarHdr, collapseH:seminarCollapseHdr },
  { typeName: "other", headers:otherHdr, collapseH:otherCollapseHdr },
  { typeName: "printed material", headers:printedMaterialHdr, collapseH:printedMaterialCollapseHdr },
  { typeName: "radio/ television", headers:RadioTelHdr, collapseH:RadioTelCollapseHdr },
  { typeName: "sms", headers:smsHdr, collapseH:smsCollapseHdr },
];

export const onlineHeaders =[
  { typeName: "social media", headers:socialMediaHdr, collapseH:socialMediaCollapseHdr },
  { typeName: "paid media", headers:paidMediaHdr, collapseH:paidMediaCollapseHdr },
  { typeName: "website", headers:websiteHdr, collapseH:websiteCollapseHdr },
  { typeName: "video content", headers:videoContentHdr, collapseH:videoContentCollapseHdr },
  { typeName: "content creation", headers:contentCreationHdr, collapseH:contentCreationCollapseHdr },
  { typeName: "newsletter", headers:newsLetterHdr, collapseH:newsLetterCollapseHdr },
  { typeName: "other digital marketing", headers:otherDigitalMarketingHdr, collapseH:otherDigitalMarketingCollapseHdr },
  { typeName: "online event", headers:onlineEventHdr, collapseH:onlineEventCollapseHdr },
];
