import React from "react";
import {Navigate, useLocation,useNavigate } from "react-router-dom";
import Authorization from '../utils/authorization';
import {SessionWeb} from "../utils/session";
import { useDispatch, useSelector } from "react-redux";
import cookie from 'js-cookie'


export default function PrivateRoute({ children,roles }) {
  const authUser= Authorization.getAuthUser();
  const userHasRequiredRole =  authUser && roles?.length>0&& roles?.includes(authUser.userrole) ? true : false;
  const location = useLocation();
  const ContractActionType = useSelector((state) => state.common.ContractActionType);
  const SessionExpired = SessionWeb()
  const { naviBlocker } = useSelector((state) => state.common); 
  const navigate = useNavigate();
  const dispatch = useDispatch();
//  if(!SessionExpired && cookie.get('SESSION_BAYAGRI')){
//   // if (cookie.get('SESSION_BAYAGRI_STATUS') === 'DEACTIVE') {
//     window.alert('Session Expired ! Please login again.')
//   // }
//   cookie.remove('SESSION_BAYAGRI')
//   !naviBlocker.naviBlock &&
//   Authorization.logout();
//   !naviBlocker.naviBlock &&
//   dispatch({ type: "RESET_STORE" });
//   navigate("/login");
//  }
 if(!SessionExpired){
  // if (cookie.get('SESSION_BAYAGRI_STATUS') === 'DEACTIVE') {
    window.alert('Session Expired ! Please login again.')
  // }
  cookie.remove('SESSION_BAYAGRI')
  !naviBlocker.naviBlock &&
  Authorization.logout();
  !naviBlocker.naviBlock &&
  dispatch({ type: "RESET_STORE" });
  navigate("/login");
 }



  if (!Authorization.isLoggedIn()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />;
  }
  if( Authorization.isLoggedIn()&& !userHasRequiredRole){
    return <Navigate to="/dashboard" />;
  }
  if(location.pathname === '/editcontract' &&  ContractActionType && ContractActionType?.type !== 'EDIT') {
    return <Navigate to="/contracts" />;
  }
  if(location.pathname.includes('accounts/') &&  ContractActionType && ContractActionType?.type !== 'ACTIVITYACCOUNTVIEW') {
    return <Navigate to="/accounts" />;
  }
  
  // authorized so return child components
  return children;
}
