import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  offlineMAList: [],
  offlineMALoading: false,
  offlineMAError: false,
};

const offlineMarketingActivities = createSlice({
  name: "offlineMarketingActivities",
  initialState,
  reducers: {
    getOfflineMA(state) {
      state.offlineMALoading = true;
      state.offlineMAList = [];
    },
    getOfflineMA_successful(state, action) {
      state.offlineMAList = action.payload.body;
      state.offlineMALoading = false;
    },
    getOfflineMA_error(state) {
      state.offlineMAError = true;
    },
  },
});

export const { getOfflineMA, getOfflineMA_successful, getOfflineMA_error } = offlineMarketingActivities.actions;

export default offlineMarketingActivities.reducer;
