import { Fragment, useEffect, useState } from "react";
import { getActivities } from "../../../../saga/saga/activitiesSaga";
import Authorization from "../../../../utils/authorization";
import util from "util";
import { apiURL } from "../../../../services/apiUrlConfig";
import { useDispatch, useSelector } from "react-redux";
import ActivityTypeView from "./ActivityTypeView";

export default function ActivityTypesModel() {
  const [activities, setActivities] = useState([]);
  const getApi = apiURL.ppp.getActivities ?? "";
  const userDetaisl = Authorization.authUser;
  const activitisApi = util.format(getApi, userDetaisl.id);
  const dispatch = useDispatch();
  const activitiesList = useSelector((state) => state.activitiesSaga.activitiesList);
  const activitiesStatus = useSelector((state) => state.activitiesSaga.activitiesloading);

  useEffect(() => {
    dispatch(getActivities(activitisApi));
  }, []);

  useEffect(() => {
    setActivities(activitiesList);
  }, [activitiesList]);

  return (
    <Fragment>
      <ActivityTypeView loadWhie={activitiesStatus} AccTypes={activities} />
    </Fragment>
  );
}
