import * as React from "react";
// material-ui
import { Grid, ListItemText, Box, styled } from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Skeleton from "@mui/material/Skeleton";
import MainCard from "../MainCard";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#89D329",
  },
}));

export const CustomListItemText = ({ primaryText, secondaryText }) => {
  return (
    <ListItemText
      primary={primaryText}
      secondary={secondaryText}
      primaryTypographyProps={{
        color: "#96A8AE",
        fontSize: "0.75rem",
      }}
      secondaryTypographyProps={{
        color: "#323232",
        fontSize: "0.875rem",
      }}
    />
  );
};

export const LoaderSkeleton = () => {
  return [...Array(6)].map(() => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard elevation={0} border={true} content={true} contentSX={{ p: 2 }}>
          <Skeleton animation="wave" variant="rectangular" sx={{ height: 150, width: "100%", backgroundColor: "#F2F2F2" }} />
          <Box sx={{ width: "100%" }}>
            <BorderLinearProgress sx={{ height: 5 }} />
          </Box>
        </MainCard>
      </Grid>
    );
  });
};

const StyledTabList = styled(TabList)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    height: "3.05px",
    borderRadius: "2.5px",
  },
}));
const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: "5px",
}));
export const CusTabs = ({ tabListData, tabPanelData, isScrollable }) => {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <StyledTabList onChange={handleChange} aria-label="lab API tabs example">
            {tabListData?.length > 0 &&
              tabListData.map((tld) => {
                return <Tab label={tld?.label} value={tld.value} />;
              })}
          </StyledTabList>
        </Box>
        {tabPanelData?.length > 0 &&
          tabPanelData.map((tpd) => {
            return (
              <StyledTabPanel
                value={tpd.value}
                sx={
                  isScrollable && {
                    maxHeight: {
                      xl: 850,
                      lg: 410,
                    },
                    overflowX: "auto",
                  }
                }
              >
                {tpd?.content}
              </StyledTabPanel>
            );
          })}
      </TabContext>
    </Box>
  );
};
