import React,{ Fragment, useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CollapsibleTable from '../../../../components/ui/CollapsibleTable';
import { Paper, Box, FormControl, Select, MenuItem  } from '@mui/material';
import { apiURL } from '../../../../services/apiUrlConfig';
import authorization from '../../../../utils/authorization';
import util from 'util'
import { useDispatch, useSelector } from "react-redux";
import { getOrderManagementAct, resetStatus } from "../../../../saga/saga/orderManagementActSaga";


const headCells = [
  {
    id: "cropname",
    numeric: false,
    disablePadding: true,
    label: "Crop",
    minWidth: 200,
    isInputBox: false,
    isLast: false,
  },
  {
    id: "productname",
    numeric: false,
    disablePadding: false,
    label: "Variety",
    minWidth: 200,
    isInputBox: false,
    isLast: false,

  },
  {
    id: "total_units_num__c",
    numeric: true,
    disablePadding: true,
    label: "Unit",
    minWidth: 150,
    isInputBox: false,
    isLast: false,

  },
  {
    id: "quantity__c",
    numeric: true,
    disablePadding: false,
    label: "Order Quantity",
    minWidth: 150,
    isInputBox: false,
    isLast: false,

  },
  {
    id: "requested_delivery_date__c",
    numeric: true,
    disablePadding: false,
    label: "Delivery Date",
    minWidth: 200,
    isInputBox: false,
    isLast: false,

  },
  // {
  //   id: "details",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Details",
  //   minWidth: 0,
  //   isInputBox: false,
  //   isLast: false,

  // },
];







const TimeStatus = ({text,value}) => {
  const res = ''
    switch(text){
      case 'onTime':
        return <>&nbsp;&nbsp;<span style={{ color:'green', fontSize: '20px'}}>●</span> &nbsp; On Time</>       
      case 'days':
        return <>&nbsp;&nbsp;<span style={{ color:'orange', fontSize: '20px'}}>●</span> &nbsp; Delayed by { value >1 ? `${value}days`: `${value} day`} </>       
        case 'months':
          return <>&nbsp;&nbsp;<span style={{ color:'orange', fontSize: '20px'}}>●</span> &nbsp; Delayed by { value >1 ? 'months': `${value} month`} </>
        default:
          return ''
    }
}
const OrderManagement = () => {
  const [rows, setRows] = React.useState([])
  const authUser = authorization.getAuthUser();

  const dispatch = useDispatch();
  const getOrderManagementActivities = useSelector((state) => state.OrderManagementAct?.actOrderManagementList);


  const getLoadData = () => {
    const {
      ppp: { activities },
    } = apiURL;
    const condUrl = util.format(activities.getOrderManagementActivities, authUser?.id,24 );
    const queryParams = `?pppstage=${"ACTIVITIES"}`
    dispatch(getOrderManagementAct(condUrl+queryParams));
  };

useEffect(() => {
  getLoadData();
}, []);

useEffect(() => {
  setRows( getOrderManagementActivities.orders);
}, [getOrderManagementActivities]);

useEffect(() => {
  return () => {
    dispatch(resetStatus());
 }
}, [])



  const SelectionAccounnts = () => {
    const accountsOptions = []
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={""}
          onChange={() => { }}
          style={{
            width: 250,
            height: 40,
            fontSize: 12,
            backgroundColor: "white",
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            disableScrollLock: true,
          }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <em style={{ fontStyle: "normal" }}>
                  {"List of accounts selected"}
                </em>
              );
            }
            return selected.join(", ");
          }}
        >
          <MenuItem sx={{ fontSize: 11 }} value="all">
            {"All accounts"}
          </MenuItem>

        </Select>
      </FormControl>
    );
  };

  const [expanded, setExpanded] = useState(); //useState('lineList0');
  const handleChange = (panel) => (newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const drawerOpen = ""
  return (
    <Fragment>

      <Box display="flex" flexDirection="column" gap={1.5}>
        <Box display={"flex"} gap={1} justifyContent="space-between">
          <Box display={"flex"} gap={1}>

            <Typography
              variant="body2"
              style={{
                fontSize: "18px",
                marginTop: "1vh",
              }}
            >
              Order Management 
            </Typography>
          </Box>
          <SelectionAccounnts />
        </Box>

        <Paper elevation={0} sx={{ width: "100%" }}>          
          { rows?.length > 0 && rows?.map((item, key) => {
         return<> <Accordion key={key} sx={{ m: 3 }} expanded={expanded === `lineList${key}`}  onChange={handleChange(`lineList${key}`)}>
            <AccordionSummary
              sx={{
                backgroundColor: "#EBF0F2"
              }}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>{item.name} <TimeStatus text="onTime" value=''/> </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <CollapsibleTable
        statusLoad={false}
        rows={item?.lineitems}
        headCells={headCells}
        getFilterData={() => {}}
        isPagination={true}
        getAPICall={() => {}}
        collapseHeaderCells= {[
          { label: "Priming",  id: "primed__c", minWidth: 500, userInteration: false },
          { label: "Treatment",  id: "ord_treatment__c", minWidth: 500, userInteration: false },
          { label: "Packaging Type", id: "ord_packaging__c", minWidth: 500, userInteration: false },
          { label: "Comments",  id: "customer_requirements__c", minWidth: 500, userInteration: false },
        ]}
        getSelectedRowTable={() => {}}
      />

             
            </AccordionDetails>
          </Accordion>
          </>
          })} 
        </Paper>
      </Box>

    </Fragment>
  )
}


export default OrderManagement;