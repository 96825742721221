import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onlineMAList: [],
  onlineMALoading: false,
  onlineMAError: false,
};

const onlineMarketingActivities = createSlice({
  name: "onlineMarketingActivities",
  initialState,
  reducers: {
    getOnlineMA(state) {
      state.onlineMALoading = true;
      state.onlineMAList = [];
    },
    getOnlineMA_successful(state, action) {
      state.onlineMAList = action.payload.body;
      state.onlineMALoading = false;
    },
    getOnlineMA_error(state) {
      state.onlineMAError = true;
    },
  },
});

export const { getOnlineMA, getOnlineMA_successful, getOnlineMA_error } = onlineMarketingActivities.actions;

export default onlineMarketingActivities.reducer;
