// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import loginReducer from "../../features/login/loginSlice";
import common from "./common";
import contracts from "./contracts"
import activitiesSaga from "../../saga/saga/activitiesSaga"
import contractSaga from "../../saga/saga/contractSaga"
import orderMangeSaga from "../../saga/saga/orderMangeSaga"
import salesVolumeSaga from "../../saga/saga/salesVolumeSaga"
import paymentManageSaga from "../../saga/saga/paymentManageSaga"
import createdContractSaga from "../../saga/saga/createdContractSaga"
import foreCastSaga from "../../saga/saga/foreCastSaga"
import MarketIntelligenceSaga from "../../saga/saga/MarketIntelligenceSaga"
import demandGenerationSaga from "../../saga/saga/demandGenerationSaga"
import createDocumentSaga from "../../saga/saga/createDocumentSaga"
import documentUpload from "../../saga/saga/documentUpload"
import documentSave from "../../saga/saga/documentSave"
import activities from "./activities";
import uploadSignedDoc from "../../saga/saga/uploadSignedDoc";
import downloadFileSaga from "../../saga/saga/downloadFileSaga"
import advancementTrails from "../../saga/saga/advancementTrails"
import offlineMarketingAct from "../../saga/saga/offlineMarketingAct";
import OrderManagementAct from "../../saga/saga/orderManagementActSaga";
import onlineMarketingAct from "../../saga/saga/onlineMarketingAct";
import approveMarketingActivity from "../../saga/saga/approveMarketingActivity";




// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers(
   {
      menu,
      loginReducer,
      common,
      contracts,
      activitiesSaga,
      contractSaga,
      orderMangeSaga,
      salesVolumeSaga,
      paymentManageSaga,
      activities,
      foreCastSaga,
      createdContractSaga,
      MarketIntelligenceSaga,
      demandGenerationSaga,
      createDocumentSaga,
      documentUpload,
      documentSave,
      uploadSignedDoc,
      downloadFileSaga,
      advancementTrails,
      offlineMarketingAct,
      onlineMarketingAct,
      OrderManagementAct,
      approveMarketingActivity
   });

// ==============================|| COMBINE REDUCERS ||============================== //

/**
 * Function is used to reset store data
 * @param {*} state
 * @param {*} action
 * @returns
 */
const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    state = {};
  }
  return reducers(state, action);
};
export default rootReducer;
