// assets
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// ==============================|| MENU ITEMS ||============================== //

// icons
const icons = {
  DashboardIcon,
  LogoutIcon,
  PublicOutlinedIcon,
  ReportOutlinedIcon,
  ReceiptLongOutlinedIcon,
  PermIdentityOutlinedIcon,
  GridViewIcon,
  EditNoteIcon,
  FormatListBulletedIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "group-dashboard",
  title: "Navigation",
  type: "group",
  role:"ADMIN",
  children: [
    {
      id: "Dashboard",
      title: "App Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons.DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "Map View",
      title: "Trial Location",
      type: "item",
      url: "/trial-location",
      icon: icons.PublicOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "targetdashboard",
      title: "Target Dashboard",
      type: "item",
      url: "/target-dashboard",
      icon: icons.GridViewIcon,
      breadcrumbs: false,
    },
  ],
};

const dashboardCMM = {
  id: "cmm-dashboard",
  title: "Navigation",
  type: "group",
  role:"CMM",
  children: [
    {
      id: "Dashboard",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons.DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "contracts",
      title: "Contracts/Targets",
      type: "item",
      url: "/contracts",
      icon: icons.ReceiptLongOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "Edit Contracts",
      title: "Created Contracts",
      type: "item",
      url: "/createContracts",
      icon: icons.EditNoteIcon,
      breadcrumbs: false,
    },
    {
      id: "Activities",
      title: "Activities",
      type: "item",
      url: "/accounts",
      icon: icons.FormatListBulletedIcon,
      breadcrumbs: false,
    },
  ],
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: "authentication",
  // title: 'Authentication',
  type: "group",
  children: [
    {
      id: "logout",
      title: "Logout",
      type: "item",
      url: "/login",
      icon: icons.LogoutIcon,
      target: false,
    },
  ],
};
const menuItems = {
  items: [dashboard, pages,dashboardCMM],
};

export default menuItems;
