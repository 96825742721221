export const CMM_ROLE = "CMM";
export const ADMIN_ROLE = "ADMIN";
export const DEFAULT_NAVI_MESSAGE = "There are unsaved changes. Are you sure want to leave this page?";
export const LOGOUT_NAVI_MESSAGE = "Are you sure want to logout?";
export const CONTRACT_DOCUMENT_POPUP_MESSAGE = "There are unsaved changes. Are you sure want to close?";
export const CONTRACT_YEAR = Number(process.env.REACT_APP_CONTRACT_YEAR);
export const PAYMENT_MANAGEMENT_COUNTRY_DAYS = {
  TR: [9], // Turkey
  MA: [30], // Morocco
  DZ: [30], // Algeria
  TN: [30], // Tunisia
  EG: [30], // Egypt
  IQ: [30], // Iraq
  JO: [30], // Jordan
  LB: [30], // Lebanon
  SY: [30], // Syria
  SA: [30], // Saudi
  OTHERS: [5],
};
export const ACTIVITIES_CUSTOM_ORDER = [1, 2, 3, 4, 5, 12, 6, 7, 8, 9, 10, 11];
export const ACTIVITIES_HELPER_TEXT = [
  { url: "https://mvs--test.sandbox.my.salesforce.com/a0l?fcf=00BE0000004Io4V", env: "UAT", type: "SALES_TARGET", activities: 3 },
  { url: "https://mvs--test.sandbox.my.salesforce.com/a19?fcf=00BE0000004h1R5", env: "UAT", type: "MARKET_INTELLIGENCE", activities: 4 },
  { url: "https://mvs--test.sandbox.my.salesforce.com/a6C?fcf=00BUu0000008JqH", env: "UAT", type: "TRIAL_PLANS", activities: 5 },
  { url: "https://mvs--test.sandbox.my.salesforce.com/a6C?fcf=00BUu0000008JqH", env: "UAT", type: "TRIAL_PLANS", activities: 12 },
  { url: "https://mvs.my.salesforce.com/a0l?fcf=00BE0000004Io4V", env: "PROD", type: "SALES_TARGET", activities: 3 },
  { url: "https://mvs.my.salesforce.com/a19?fcf=00BE0000004h1R5", env: "PROD", type: "MARKET_INTELLIGENCE", activities: 4 },
  { url: "https://mvs.my.salesforce.com/a19?fcf=00BE0000004h1R5", env: "PROD", type: "TRIAL_PLANS", activities: 5 },
  { url: "https://mvs.my.salesforce.com/a19?fcf=00BE0000004h1R5", env: "PROD", type: "TRIAL_PLANS", activities: 12 },
];
export const Childs = {
  ACCOUNTS: "accounts",
  ACTIVITIES: "activities",
  ORDERMANAGEMENT: "ordermanagement",
  PAYMENTMANAGEMENT: "paymentmanagement",
  FORECASTINFO: "forecastinfo",
  MARKETINTELLIGENT: "marketintelligent",
  DEMANDGENERATIONTRIALS: "demandgenerationtrials",
  OFFLINEMARKETING: "offlinemarketing",
  ONLINEMARKETING: "onlinemarketing",
  LONGTERMBUSSINESSSOLUTION: "longtermbussinesssolution",
  SALESVOLUME: "salesvolume",
  BRANDEXECUTION: "brandexecution",
  POGDELIVERY: "pogdelivery",
  PERFORMANCETRIALS: "performancetrials",
  ACTIVITYTYPE: "activitytype",
};

export const marketingActivitiesTypes = [
  {
    mode: "online",
    data: [
      { group: "group_1", types: ["social media", "paid media", "website", "video content", "content creation"], isLargeTable: false },
      { group: "group_2", types: ["newsletter"], isLargeTable: false },
      { group: "group_3", types: ["other digital marketing"], isLargeTable: false },
      { group: "group_4", types: ["online event"], isLargeTable: true },
    ],
  },
  {
    mode: "offline",
    data: [
      {
        group: "group_1",
        types: ["fair expo", "field day", "field visit", "large scale field day", "meeting", "merchandize", "seminar", "small scale field day"],
        isLargeTable: true,
      },
      { group: "group_2", types: ["other", "printed material", "radio/ television", "sms", "merchandize"], isLargeTable: false },
    ],
  },
];
/**
 * This constant for Offline and Online Marketing Activities header section p
 */
// const activity_types = [
//   //online
//   //group 1
//   "social media",
//   "paid media",
//   "website",
//   "video content",
//   "content creation",
//   //group2
//   "newsletter",
//   //group3
//   "other digital marketing",
//   //group4
//   "online event",
//   //offline
//   //group 1
//   "fair expo",
//   "field day",
//   "field visit",
//   "large scale field day",
//   "meeting",
//   "seminar",
//   "small scale field day",
//   //offline group 2
//   "other",
//   "printed material",
//   "radio/ television",
//   "sms",
//   "merchandize",
// ];
export const OnandOffMarketingHeader = [
  {
    id: "activity_name__c",
    numeric: false,
    disablePadding: true,
    label: "Activity Name",
    minWidth: 130,
    isInputBox: false,
    isLast: false,
    userInteration: false,
    ids: [],
    isMaxWidthApply: false,
  },
  {
    id: "date__c",
    numeric: false,
    disablePadding: false,
    label: "Date of Event",
    minWidth: 82,
    isInputBox: true,
    isLast: false,
    userInteration: false,
    type: "date",
    ids: [],
    isMaxWidthApply: false,
  },
  {
    id: "no_of_participants__c",
    numeric: true,
    disablePadding: true,
    label: "Participants",
    minWidth: 85,
    isInputBox: false,
    isLast: false,
    userInteration: false,
    ids: ["fair expo", "field day", "field visit", "large scale field day", "meeting", "online event", "seminar", "small scale field day", "website"],
    isMaxWidthApply: false,
  },
  {
    id: "event_location__c",
    numeric: true,
    disablePadding: false,
    label: "Location",
    minWidth: 85,
    isInputBox: false,
    isLast: false,
    userInteration: false,
    ids: ["fair expo", "field day", "field visit", "large scale field day", "meeting", "seminar", "small scale field day"],
    isMaxWidthApply: false,
  },
  {
    id: "description__c",
    numeric: true,
    disablePadding: true,
    label: "Description",
    minWidth: 100,
    isInputBox: false,
    isLast: false,
    userInteration: false,
    ids: [
      "fair expo",
      "field day",
      "field visit",
      "small scale field day",
      "large scale field day",
      "seminar",
      "meeting",
      "online event",
      "printed material",
      "merchandize",
      "other",
      "radio/ television",
      "sms",
    ],
    isMaxWidthApply: false,
  },
  {
    id: "link__c",
    numeric: true,
    disablePadding: true,
    label: "Activity Link",
    minWidth: 85,
    isInputBox: false,
    isLast: false,
    userInteration: false,
    ids: [
      "content creation",
      "fair expo",
      "field day",
      "field visit",
      "small scale field day",
      "large scale field day",
      "meeting",
      "online event",
      "other digital marketing",
      "seminar",
      "paid media",
      "social media",
      "video content",
      "website",
    ],
    isMaxWidthApply: false,
  },
  {
    id: "consent_to_publish__c",
    numeric: true,
    disablePadding: true,
    label: "Consent to Publish",
    minWidth: 140,
    isInputBox: false,
    isLast: false,
    userInteration: false,
    ids: [
      "content creation",
      "fair expo",
      "field day",
      "field visit",
      "small scale field day",
      "large scale field day",
      "meeting",
      "online event",
      "paid media",
      "seminar",
      "social media",
      "video content",
      "website",
    ],
    isMaxWidthApply: false,
  },
  {
    id: "status__c",
    numeric: true,
    disablePadding: false,
    label: "Status",
    minWidth: 85,
    isInputBox: false,
    isLast: false,
    userInteration: false,
    ids: [
      "content creation",
      "fair expo",
      "field day",
      "field visit",
      "large scale field day",
      "meeting",
      "merchandize",
      "online event",
      "other",
      "printed material",
      "radio/ television",
      "seminar",
      "small scale field day",
      "sms",
      "social media",
      "paid media",
      "video content",
      "website",
    ],
    isMaxWidthApply: false,
  },
  {
    id: "photos",
    numeric: true,
    disablePadding: false,
    label: "Photos",
    minWidth: 85,
    isInputBox: false,
    isLast: false,
    userInteration: false,
    ids: [
      "fair expo",
      "field day",
      "field visit",
      "large scale field day",
      "meeting",
      "online event",
      "printed material",
      "merchandize",
      "other",
      "radio/ television",
      "sms",
      "seminar",
      "small scale field day",
    ],
    isMaxWidthApply: false,
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
    minWidth: 62,
    isInputBox: false,
    isLast: false,
    userInteration: true,
    ids: [],
    isMaxWidthApply: false,
  },
];
