import { Fragment, useRef, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import CustomTable from '../../../../components/ui/Table';
import { Paper, Box, FormControl, Select, MenuItem  } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { apiURL } from '../../../../services/apiUrlConfig';
import authorization from '../../../../utils/authorization';
import util from 'util'
import { useDispatch, useSelector } from "react-redux";
import { getOrderManagementAct as getSalesVolumeAct, resetStatus } from "../../../../saga/saga/orderManagementActSaga";


const headCells = [
  {
    id: "tiers",
    numeric: false,
    disablePadding: false,
    label: "Tiers",
    minWidth: 200,
    isInputBox: false,
    isLast: false,
  },
  {
    id: "netInvoicedPurchases",
    numeric: false,
    disablePadding: false,
    label: "Net Invoiced Purchases",
    minWidth: 120,
    isInputBox: false,
    isLast: false,

  },
  
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#00617F',
      color: theme.palette.common.white,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 11,
      fontWeight: 100
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      paddingTop: 10,
      paddingBottom: 9,
      fontWeight: 100
  },
}));
const LoadWhile = (statusLoad) => {
      return statusLoad ? (
          [...Array(4)].map((item, itemIndex) => {
              return (
                  <TableRow key={itemIndex}>
                      <TableCell
                          colSpan={headCells?.length + 1}
                          sx={{ textAlign: "center" }}
                          style={{
                              borderBottom: "0px solid transparent",
                              paddingBottom: 0.5,
                              paddingRight: 2,
                              paddingLeft: 0,
                          }}
                      >
                          <Skeleton
                              animation="wave"
                              variant="rectangular"
                              sx={{ height: 60, width: "100%", backgroundColor: "#F2F2F2" }}
                          />
                      </TableCell>
                  </TableRow>
              );
          })
      ) : (
          <TableRow>
              <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
                  {" "}
                  No records found!
              </TableCell>
          </TableRow>
      );
  };

const SalesVolume = () => {
  const inputRef = useRef(null)
  const [rows, setRows] = useState([])
  const [pysales, setPysales] = useState()
  const [statusLoad,setStatusLoad] = useState(false);
  const authUser = authorization.getAuthUser();
  const dispatch = useDispatch();
  const getSalesVolumeActivities = useSelector((state) => state.OrderManagementAct?.actOrderManagementList);
  const drawerOpen = ""

  const getLoadData = () => {
    const {
      ppp: { activities },
    } = apiURL;
    const condUrl = util.format(activities.getSalesVolumeActivities, authUser?.id,24 );
    const queryParams = `?pppstage=${"ACTIVITIES"}`
    dispatch(getSalesVolumeAct(condUrl+queryParams));
  };

  useEffect(() => {
    getLoadData();
  }, []);

  useEffect(() => {   
    keyAsColumns(getSalesVolumeActivities)
  }, [getSalesVolumeActivities]);

  useEffect(() => {
    return () => {
      dispatch(resetStatus());
   }
}, [])


  const keyAsColumns = (val) => {
    let keys = Object.keys(val)
    let rows = []
    for(let i = 0; i < keys.length; i++){
      if(['base','silver','gold','platinum'].includes(keys[i])){      
      rows = [...rows, {
        'tiers': keys[i].charAt(0).toUpperCase() + keys[i].slice(1),
        'netInvoicedPurchases' :  (i>0 && keys[i] !== 'base' )  ?
      (keys[i] !== 'platinum') ? val.currency+' ' + (val[keys[i-1]] + 1).toLocaleString() +' - ' + val.currency+' '+ val[keys[i]].toLocaleString() : val.currency+' ' +val[keys[i]].toLocaleString() + ' +'
        : val.currency+' ' + 0 + ' - ' + val.currency+' ' +  val[keys[i]].toLocaleString()  }]
      }
    }   
    setRows( rows);
    const getPysales = val.pysales && val.pysales.toLocaleString()
    setPysales(val.currency+' '+getPysales)
  }

  const SelectionAccounnts = () => {
    const accountsOptions = [] 
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={""}
          onChange={() => { }}
          style={{
            width: 250,
            height: 40,
            fontSize: 12,
            backgroundColor: "white",
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            disableScrollLock: true,
          }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <em style={{ fontStyle: "normal" }}>
                  {"List of accounts selected"}
                </em>
              );
            }
            return selected.join(", ");
          }}
        >
          <MenuItem sx={{ fontSize: 11 }} value="all">
            {"All accounts"}
          </MenuItem>

        </Select>
      </FormControl>
    );
  };

  return (
    <Fragment>

      <Box display="flex" flexDirection="column" gap={1.5}>
        <Box display={"flex"} gap={1} justifyContent="space-between">
          <Box display={"flex"} gap={1}>

            <Typography
              variant="body2"
              style={{
                fontSize: "18px",
                marginTop: "1vh",
              }}
            >
              Sales Volume
            </Typography>
          </Box>
          <SelectionAccounnts />
        </Box>
      
        <Paper elevation={0} sx={{ width: "100%" }}>
            <Box sx={{  m: 3 }}>
        <Typography>Year: 2024</Typography>
        <Typography sx={{  mb: 1 }}>Year-To-Date Net Invoiced Purchases: <strong>{pysales}</strong></Typography>
       
       
      <TableContainer style={{ overflow: 'auto', height: 370 }} ref={inputRef} component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ backgroundColor: '#1a648d' }}>
                        <TableRow>
                            {
                                headCells.map((item, index) => (
                                    <StyledTableCell key={index} width={item.minWidth} sx={{ color: 'white', fontWeight: 'normal' }}>{item.label}</StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rows?.length > 0 && headCells?.length > 0) ? rows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0, background:'#EBF0F2' } }}
                            >
                                {headCells?.length > 0 && headCells.map((itm,i) => {
                                    return <StyledTableCell key={i} style={{ minWidth: itm.minWidth }} align="left">{(row[itm?.id] && row[itm?.id]) ? (row[itm?.id] && row[itm?.id]) : '-'}</StyledTableCell>
                                })}
                            </TableRow>
                        )) : <LoadWhile statusLoad = {statusLoad} />}

                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </Paper>
      
      </Box>
    </Fragment>
  )
}


export default SalesVolume;